import anime from 'animejs/lib/anime.es.js';
import {Cursor} from './modules/cursor/cursor';
import {Experiments} from './experiments';
import colorpicker from './modules/color-picker/color-picker';
import confetti from 'canvas-confetti/dist/confetti.module.mjs';

const $ = document.querySelector.bind(document);
const $$ = document.querySelectorAll.bind(document);
const doc = document.documentElement;
const CURRENT_TIME = Date.now();

export default () => {
    let isHome = document.body.classList.contains('home');

    let cursorEl = new Cursor();
    colorpicker();

    let loopVideos = Array.from(document.querySelectorAll('[autoplay]'));
    loopVideos.forEach((v)=>{
        v.playsInline = true
        v.setAttribute('muted', '');
        v.defaultMuted = true;
        v.play();
    });

    const scrollTo = element => {
      const elementSelector = element;

        const elementOffset = elementSelector.getBoundingClientRect().top;
        const scrollPosition = window.scrollY;
        const documentTop = document.documentElement.clientTop;
        const scrollOffset = elementOffset + scrollPosition - documentTop;
        anime({
          targets: [document.documentElement, document.body],
          scrollTop: scrollOffset,
          duration: 800,
          easing: 'easeOutExpo'
        });
    }

    let hrefs = Array.from(document.querySelectorAll("a[href*='#']"));

    hrefs.forEach((href)=>{
        href.addEventListener('click', (e)=>{
            e.preventDefault();
            scrollTo(document.querySelector(href.getAttribute('href')));
        });
    });

    let isExperiemnts = document.body.classList.contains('experiments');
    if (isExperiemnts){
        let experiments = new Experiments():
    }

    if (isHome){
        var isSent = false;

        let video = $('video');
        let intro = $('section.intro');
        let greetEl = $('.greet');
        let areEl = $('.are');
        let greet = ['hi', 'grüetzi', 'bonjour'];
        let are = ['we are', 'my jsme', 'mir sy'];
        let fab = $('.fab');

        let btnHi = $('.about .btn');
        let btnSnd = $('.about .btn--snd');
        let textarea = $('.about textarea');
        let homeWrapper = $('.home-wrapper');
        let arrow = $('.btn__icon');
        let currentText = $$('.about h1, .about p');
        let buttonWrapper = $('.about__buttons');

        let sameReferrer = document.referrer.indexOf(location.protocol + "//" + location.host) === 0;

        textarea.addEventListener('keyup', (ev)=>{
            let hasContent = textarea.value.length > 0 ;
            doc.classList.toggle('has-content', hasContent);
        });

        btnHi.addEventListener('click', (ev)=>{
            if (isSent){
                return;
            }

            let isWriting = doc.classList.toggle('is-writing');
            let padding = isWriting ? 48 : 0;
            let scrollY = document.documentElement.scrollTop || document.body.scrollTop;

            let rect = btnHi.getBoundingClientRect();
            let delta = isWriting ? window.innerHeight - rect.top - rect.height - scrollY : 0 ;


            homeWrapper.style.transform = `translateY(${delta}px)`;
            buttonWrapper.style.transform = `translateY(${delta - padding}px)`;

            anime({
                targets: cursor,
                y: delta
            });

            window.scrollTop = 0;

            if (isWriting){
                textarea.focus();
            } else {
                textarea.blur();
                setTimeout(()=>{
                    textarea.value = '';
                }, 500);
            }
        });

        let sendMessage = function(){
            doc.classList.remove('is-writing');
            textarea.blur();
            homeWrapper.style.transform = `translateY(0px)`;
            buttonWrapper.style.transform = `translateY(0px)`;
            arrow.innerHTML = '✔';
            btnHi.lastElementChild.innerHTML = 'thank you';
            isSent = true;

            let httpRequest = new XMLHttpRequest();
            httpRequest.open('POST', '/wp-admin/admin-ajax.php', true);
            httpRequest.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            httpRequest.send(`action=azumbrunnen_contact&message=${encodeURIComponent(textarea.value)}&email=undefined`);
            setTimeout(()=>{
                function randomInRange(min, max) {
                  return Math.random() * (max - min) + min;
                }

                confetti({
                  particleCount: 100,
                  spread: 70,
                  origin: { y: 0.6 }
                });
            }, 200);
        }

        btnSnd.addEventListener('click', ()=>{
            sendMessage();
        });

        var i = 0;

        fab.addEventListener('click', ()=>{
            let wordsDrawer = document.querySelector('.words__drawer');
            let toggledState = fab.classList.toggle('fab--toggled');

            anime({
                targets: wordsDrawer,
                height: toggledState ? wordsDrawer.firstElementChild.getBoundingClientRect().height + 44 : 0,
                easing: 'easeOutQuint',
                duration: 1500
            });
        });

        setTimeout(()=>{
            setInterval(()=>{
                greetEl.innerHTML = greet[i%3];
                i++;
            }, 4000);
        }, 3000);


        if (sameReferrer){
            intro.parentNode.removeChild(intro);
        }

        else {
            document.documentElement.classList.add('is-locked');

            window.addEventListener('DOMContentLoaded', (event) => {
                window.scrollTo(0, 0);
                document.body.scrollTo(0, 0);
                video.playsInline = true
                video.setAttribute('muted', '');
                video.defaultMuted = true;
            });

            video.addEventListener('ended',()=>{
                anime({
                    targets: document.body,
                    scrollTop: intro.getBoundingClientRect().height,
                    duration: 1500,
                    easing: 'easeInOutQuint',
                    complete: ()=>{
                        doc.classList.remove('is-locked');
                        let s = document.querySelector('section');
                        s.parentNode.removeChild(s);
                        window.scrollTo(0, 0);
                        document.body.scrollTo(0, 0);
                    }
                  });
            });
        }
    }
};

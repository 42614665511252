export default () => {
    let colorNode = document.querySelector('.colors__segments');
    if (colorNode){
        let segments = Array.from(colorNode.children);

        segments.forEach((s)=>{
            s.addEventListener('click', ()=>{
                segments.forEach((s)=>s.classList.remove('colors__segments__item--active'));
                s.classList.add('colors__segments__item--active');
            });
        });
    }
}

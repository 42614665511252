import BezierEasing from 'bezier-easing';
let expo = BezierEasing(0.190, 1.000, 0.220, 1.000);
let sine = BezierEasing(0.250, 0.460, 0.450, 0.940);
let map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
let expoMap = (value, x1, y1, x2, y2) => expo(map(value, x1, y1, x2, y2))
let sineMap = (value, x1, y1, x2, y2) => sine(map(value, x1, y1, x2, y2))
let quadMap = (value, x1, y1, x2, y2) => expo(map(value, x1, y1, x2, y2))

const getBoundingClientRect = function(element) {
  var rect = element.getBoundingClientRect();
  return {
    top: parseInt(rect.top) + window.pageYOffset,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left,
    width: rect.width,
    height: rect.height,
    x: rect.x,
    y: rect.y
  };
}
function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

class Story {
    constructor(options){
        this.bodyEl = document.querySelector('body');
        this.mapFunctions = {
            linear: map,
            expo: expoMap,
            quad: quadMap,
            sine: sineMap
        };

        this.innerHeight = window.innerHeight


        this.config = Object.assign({
            triggerEl:this.bodyEl,
            triggerRect: null,
            storyTarget: this.bodyEl,
            storyTargetRect: null,
            curve: 'linear',
            marginTop: 0,
            progress: 0,
            autorun: true,
            onScroll: ()=>{},
            onInit: ()=>{}
        }, options);

        window.addEventListener('scroll', this.handleScroll.bind(this))
        if (!iOS()){
            window.addEventListener('resize', this.updateCoordinates.bind(this))
        }
        document.addEventListener('DOMContentLoaded', ()=>{
            setTimeout(()=>{
                this.innerHeight = window.innerHeight
                this.updateCoordinates();
                this.config.onInit(this.config);
                if (this.config.autorun){
                    this.config.onScroll(this.config);
                }
            }, 100);
        });
        return this;
    }

    updateCoordinates(){
        this.config.triggerRect = getBoundingClientRect(this.config.triggerEl);
        this.config.storyTargetRect = getBoundingClientRect(this.config.storyTarget);
    }

    handleScroll(){
        if (this.config.triggerRect){
            let scrollY = window.pageYOffset + this.config.marginTop;
            let outerMin = parseInt(this.config.triggerRect.top) - this.config.marginTop;
            let outerMax = outerMin + parseInt(this.config.triggerRect.height) - this.innerHeight;
            let progress = Math.min(Math.max(this.mapFunctions[this.config.curve](scrollY, outerMin, outerMax, 0, 1), 0), 1);
            this.config.progress = progress;
            this.config.onScroll(this.config);
        }
    }

}


export class Experiments{
    constructor() {
        setTimeout(()=> window.scrollTo(0,0), 50);

    	this.nav = document.querySelector('.nav');
    	this.navRect = getBoundingClientRect(this.nav);
        this.xdrTrigger = document.querySelector('.xdrwrapper');
    	this.xdrMood = document.querySelector('.xdrmood');
        this.xdrSegment = document.querySelector('.xdrsegment');
    	this.xdrTitle = document.querySelector('.xdrtitle');
    	this.xdrTitleSub = document.querySelector('.xdrtitle__sub');
        this.xdrScreen = document.querySelector('.xdrmood__screen');
        this.xdrSummary = document.querySelector('.xdrwrapper .summary');
        this.xdrScreenRect = getBoundingClientRect(this.xdrScreen);
        this.iPhoneFirst = document.querySelector('.iphones__item:first-child');
        this.iPhoneLast = document.querySelector('.iphones__item:nth-child(2)');
        this.clothDownloadDetails = document.querySelector('.cloth__download__details')
        this.artEl = document.querySelector('.art');
        this.artVideos = this.artEl.querySelectorAll('video');
        this.xdrRepeat = document.querySelector('.xdrhand .repeat');
        this.sandRepeat = document.querySelector('.sand .repeat');
        this.xdrHandVideo = document.querySelector('.xdrhand__video');
        this.sandVideo = document.querySelector('.sand__video video');
    	this.xdrBezelWidth = 21/1505;

        if(document.readyState === 'ready' || document.readyState === 'complete') {
            document.documentElement.classList.remove('is-locked');
            this.updateCoordinates();
        } else {
          document.onreadystatechange = ()=>{
            if (document.readyState == "complete") {
                this.updateCoordinates();
                document.documentElement.classList.remove('is-locked');
            }
          }
        }
    	
        this.innerHeight = window.innerHeight

    	window.addEventListener('resize', this.updateCoordinates.bind(this))

        this.artThumbs = document.querySelectorAll('.artthumbnails__item');
        this.artdescriptors = ['one', 'two', 'three', 'four', 'five', 'six'];
        this.artlist = document.querySelector('.artlist');

        this.artThumbs.forEach((thumb, i) => {
            thumb.addEventListener('click', (ev)=>{
                this.artEl.className = `art story__block art--${this.artdescriptors[i]}`;
                for (let video of this.artVideos) {
                    video.muted = true;
                    video.pause();
                    video.volume = 0;
                    video.currentTime = 0;
                }

                let video = this.artlist.children[this.artdescriptors.length - i - 1].querySelector('video');
                video.play();
            });
        });

        this.xdrRepeat.addEventListener('click', ()=> this.xdrHandVideo.play());
        this.sandRepeat.addEventListener('click', ()=> this.sandVideo.play());
        this.sandVideo.addEventListener('ended', ()=> this.sandRepeat.style.opacity = 1);
        this.xdrHandVideo.addEventListener('ended', ()=> this.xdrRepeat.style.opacity = 1);

    	document.addEventListener('DOMContentLoaded', ()=>{
    		setTimeout(()=>{
    			this.updateCoordinates();
    		}, 100);
    	});

        this.originalXdrTopRect = 0;
        this.xdrIntroStory = new Story({
            triggerEl: this.xdrTrigger,
            storyTarget: this.xdrMood,
            onScroll: (story)=>{
                let bezelWidth = 21/1505;
                let relativeScaleWidth = (window.innerWidth / story.storyTargetRect.width) + (bezelWidth * 3);
                let relativeScaleHeight = (this.innerHeight / (this.xdrScreenRect.height));

                let relativeY = story.storyTargetRect.top - this.navRect.height/2;

                let mappedProgress = Math.min(map(story.progress, 0, 0.75, 0, 1), 1);
                let mappedProgressTitle = Math.min(map(story.progress, 0.6, 0.9, 0, 1), 1);
                let mappedScale = map(mappedProgress, 0, 1, relativeScaleWidth > relativeScaleHeight ? relativeScaleWidth : relativeScaleHeight, 1);
                let mappedY = map(mappedProgress, 0, 1, relativeY, 0);

                this.xdrTitle.style.opacity = mappedProgressTitle;
                this.xdrTitle.style.transform = `translateY(${(1-mappedProgressTitle) * 24}px)`;
                this.xdrTitleSub.style.opacity = mappedProgressTitle;
                this.xdrTitleSub.style.transform = `translateY(${(1-mappedProgressTitle) * 32}px)`;
                this.appliedXdrScale = mappedScale;

                this.xdrSegment.style.opacity = story.progress >= 1 ? 1 : 0;

                this.xdrMood.style.transform = `translateY(${-mappedY}px) scale(${mappedScale})`;
            },
            onInit: (story)=>{
                this.originalXdrTopRect = story.storyTargetRect.top;
            }
        });


        let xdrHand = new Story({
            hasPlayed: false,
            triggerEl: document.querySelector('.xdrhandtrigger'),
            storyTarget: this.xdrHandVideo,
            onScroll: (story)=>{
                if (this.hasPlayedHand){
                    return;
                }
                if (story.progress < 0.6){
                    this.hasPlayedHand = true;
                    story.storyTarget.play();
                }
            },
            autorun: false
        });

        let art = new Story({
            triggerEl: document.querySelector('.artlist'),
            storyTarget: document.querySelector('.artlist .artlist__item:last-child video'),
            onScroll: (story)=>{
                if (this.hasPlayedArt){
                    return;
                }
                if (story.progress < 0.8){
                    this.hasPlayedArt = true;
                    setTimeout(()=>{
                        story.storyTarget.play();
                    }, 300);
                }
            },
            autorun: false
        });

        let sand = new Story({
            triggerEl: document.querySelector('.sand h1'),
            storyTarget: document.querySelector('.sand video'),
            onScroll: (story)=>{
                if (this.hasPlayedSand){
                    return;
                }
                if (story.progress < 0.2){
                    story.storyTarget.play();
                    this.hasPlayedSand = true;
                }
            },
            autorun: false
        });


        let iPhones = new Story({
            hasPlayed: false,
            triggerEl: document.querySelector('.story--cloth'),
            storyTarget: document.querySelector('.iphones'),
            onScroll: (story)=>{
                let mappedTransform = Math.min(map(story.progress, 0, 0.9, 0, 75), 75);
                let mappedScale = Math.min(map(story.progress, 0, 1, 1, .8), 0.8);
                this.iPhoneLast.style.transform =  `translateZ(-1000px) translateX(${(mappedTransform)}%) rotate3d(0,1,0,${(1-story.progress)*60}deg) scale(${mappedScale})`;
                this.iPhoneFirst.style.transform =  `translateZ(-1000px) translateX(${(-mappedTransform)}%) rotate3d(0,1,0,${(1-story.progress)*-60}deg) scale(${mappedScale})`;
                
                this.clothDownloadDetails.classList.toggle('cloth__download__details--visible', story.progress > 0.8 || this.innerHeight > 900);
            },
            autorun: false
        });
    }

    updateCoordinates(ev){
        if (iOS()) return
        this.navRect = getBoundingClientRect(this.nav);
        this.xdrScreenRect = getBoundingClientRect(this.xdrScreen);
        this.xdrSummaryRect = this.xdrSummary.getBoundingClientRect();
        this.xdrMood.removeAttribute('style');

        this.xdrSegment.style.marginTop = `${this.xdrSummaryRect.height - this.innerHeight}px`;

        setTimeout(()=>{
            if (ev){
                this.xdrScreenRect.height = this.xdrScreenRect.height / this.appliedXdrScale;
            }
            this.xdrScreenRect.top = this.xdrScreenRect.top / this.appliedXdrScale;
            this.xdrIntroStory.config.storyTargetRect.top = this.originalXdrTopRect;
            this.xdrIntroStory.config.onScroll(this.xdrIntroStory.config);
        }, .1);
    }
}